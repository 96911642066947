import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

import css from "../../styles/guide.css";

const TutorialPart4 = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="DayViewer Planner Tutorial - Recur entries, create clones of your entries easily."
        />
        <title>DayViewer Planner - Tutorial Part 4</title>
      </Helmet>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <TutorialSider />
        <Layout className="guide-body">
          <h1>Recur Entries</h1>
          <Row>
            <Col>
              <h3>Intro</h3>
              <p>
                We don't want this tea just once, we want it every few days. So
                next we learn how to <strong>recur</strong> entries efficiently.
              </p>

              <p>
                As with the previous tutorials, we recommend opening a new
                browser tab and follow the steps along. It should take less than
                5 minutes to complete this.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>How to Make the Same Task Appear On Other Dates</h3>
              <p>
                If you have been following on the tutorials, you should have a
                few "Tea" related entries labelled <strong>Beverage</strong>.
                Use these or any other test entry you have created. After
                logging into DayViewer, from your calendar, select the{" "}
                <strong>"Make a Cup of Tea</strong> Task entry. The side panel
                view entry / edit form will open.
              </p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-recur-view.png"
                  alt="recur"
                  title="Recurring an Entry"
                />
              </div>
              <p>
                Next click <strong>Recur</strong> tab on the top menu. A small
                calendar will show up. Select the dates you wish for the entry
                to be copied to.
              </p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-recur-form.png"
                  alt="recur entry form"
                  title="Recurring an Entry"
                />
              </div>
              <p>
                After clicking <strong>Create Entry Series</strong> button. You
                can go back to your calendar (refresh if necessary) and the new
                entries will show as image.
              </p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-recur-calendar.png"
                  alt="Recur entries on calendar"
                  title="Recurring entries on calendar"
                />
              </div>
              <p>
                You will note that all details are copied to each entry. In your
                dashboard view - if there are any task entries copied prior to
                the current time, they will show up on the Overdue list - for
                this example we have a few as indicated in the image below.
              </p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-recur-dashboard.png"
                  alt="Recur entries on dashboard"
                  title="Recurring entries on dashboard"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                As you can see entries that have been created by this recur
                method are independant clones of the original. This is so you
                can edit each individually once created.
              </p>
              <p>NB: Recur Entries is a DayViewer Pro feature.</p>
              {/* <Link to={`/tutorial-content/tutorial-3`}>
                Go to Next Tutorial
              </Link> */}
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default TutorialPart4;
